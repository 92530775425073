
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select'
import { CircularProgressbar } from 'react-circular-progressbar';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

import { GoogleMap, LoadScript,DirectionsService,DirectionsRenderer,Polyline ,Marker,InfoWindow} from '@react-google-maps/api';

import {connect} from 'react-redux'
import store from './store/store';
import {
GET_GOOD_PLACES,
GET_TARGET_PLACE,
GET_FOOT_PRINT,
GET_GEO_CODE,
GET_COUNTRIES_GEO_CODES,
ADD_LOCATION,
API_ERROR,
GET_SITUATION
  } from './store/types';
  //https://www.googleapis.com/calendar/v3/calendars/a2VubmVkeS5jaGVza2FraTFAZ21haWwuY29t/events?key=AIzaSyBX8A95cKW5aLofmMuGukh2cs76pgAD4Qo
import {getFootPrint} from './store/actions/GetFootPrint';
import {getTargetPlace } from './store/actions/GetTargetPlace';
import {getGoodPlaces} from './store/actions/GetGoodPlaces';
import {getLocationInfo} from './store/actions/ReverseGeocode';
import {getCountries} from './store/actions/GetCountriesGeo';
import {addLocation} from './store/actions/Addlocation.js';
import {getSituation} from './store/actions/GetSituation.js';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
window.store = store;
//key 6Lcwd78aAAAAAGW3gYqVBs8P7FZ6rgORsoYgh937
//secret 6Lcwd78aAAAAAFBrTozGlsjMjcw8QzAq0L-oEYLO
const GOOGLE_MAPS_APIKEY='AIzaSyBX8A95cKW5aLofmMuGukh2cs76pgAD4Qo';


const containerStyle = {
  width: '700px',
  height: '400px'
};
const center = {
  lat: -3.745,
  lng: -38.523
};
const onLoadp = polyline => {
  console.log('polyline: ', polyline)
};
const onLoadm = marker => {
  console.log('marker: ', marker)
}
const onLoadinf = marker => {
  console.log('marker: ', marker)
}

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 5,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
  geodesic: true,
         
};
const options_f = {
  strokeColor: 'yellow',
  strokeOpacity: 0.8,
  strokeWeight: 5,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
  geodesic: true,
         
};
const opt = {
  strokeColor: 'blue',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
  geodesic: true,
         
};
const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15
}

const footprint_history = [
  { value: 0, label: 'Today Only' },
  { value: 1, label: 'Today+Yesterday' },
  { value: 2, label: 'Today+ 3 days ago' },
  { value: 3, label: 'Today+ 4 days ago' },
  { value: 4, label: 'Today+ 5 days ago' },
  { value: 5, label: 'Today+ 6 days ago' }
]


const DirectionsAsAService = ({destination, origin, travelMode, callback}) => {
    return <DirectionsService
        options={{
            destination: destination,
            origin: origin,
            travelMode: travelMode,
        }}
        callback={callback}
    />
};

function rad (x) {
  return x * Math.PI / 180;
};
function getDistance(p1, p2) {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(p2.lat - p1.lat);
  var dLong = rad(p2.lng - p1.lng);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
};
const DirectionsServiceMemo = React.memo(DirectionsAsAService);

class App extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
	var d = new Date(); // get current date
	var d2=new Date();
	 d2.setHours(d2.getHours(),d2.getMinutes()+3,0,0);

    this.state = {
    total_time_s:0,
    has_set_t:false,
    then:  d.toString(),
    next:d2.toString(),
    remaining_pc:0,
    response:null,
    nav_path:null,
    selected_country:null,
    my_location:null,
    some_more_info:false,
    footprints: [ ],
    closest_target:[],
    places_can_visit:[],
    country_options:[],
    add_response:null,
    max_footprint_days:0,
     finterval:null,
     tinterval:null,
    situation:"Loading...",
    more_info:"Once you get there you have to do xyz or reach me via xxxx"
    };
 this.directionsCallback  = this.directionsCallback.bind(this);
  this.getFootPrint  = getFootPrint.bind(this);
  this.getTargetPlace  =  getTargetPlace.bind(this);
  this.getGoodPlaces  =  getGoodPlaces.bind(this);
  this.addLocation = addLocation.bind(this);
 this.getLocationInfo=getLocationInfo.bind(this);
  this.getSituation = getSituation.bind(this);
 this.onSelectChange = this.onSelectChange.bind(this);
  this.FootPrintHistoryChange= this.FootPrintHistoryChange.bind(this);

 
  }
  
 onSelectChange(value, { action, removedValue }) {
    console.log("Coury changed to:",value);
    this.setState({
        ...this.state,
	 selected_country:value
        });
  }
  FootPrintHistoryChange(value, { action, removedValue }) {
    console.log("Footprint change days to:",value.value);
   
    this.setState({
        ...this.state,
	 max_footprint_days:value.value
        });
    this.props.getFootPrint(value.value);
  }
  handlecheckboxChange = (event) =>{
    this.setState({
      ...this.state,
      some_more_info:  event.target.checked 
    });

  }
    handleInfoChange = (event) =>{
    this.setState({
      ...this.state,
     more_info:  event.target.value 
    });

  }   
  handleBClick(){
	let data_buffer=this.state.selected_country!=null?this.state.selected_country:[];
	data_buffer["info"]=this.state.some_more_info?this.state.more_info:"";
           console.log("Adding:",data_buffer);
            this.props.addLocation(data_buffer);
               
        }		    
		    
  directionsCallback (response) {
    console.log(response)

    if (response !== null) {
      if (response.status === 'OK') {
        this.setState(
          () => ({
            response
          })
        )
      } else {
        console.log('response: ', response)
      }
    }
  }
  
  componentDidMount() {
    this._isMounted = true;
    if( this._isMounted){
        console.log("Mounted scan component!");
        
   setTimeout(getCountries(), 100);
 setTimeout(getSituation(), 500);
   setTimeout(getFootPrint(this.state.max_footprint_days), 500); 
   
  let interval2 = setInterval(getFootPrint(this.state.max_footprint_days), 240000);   

  this.setState({
        ...this.state,
	 finterval:interval2
        });
    

   

    store.subscribe(() => {
      // When state will be updated(in our case, when items will be fetched), 
      // we will update local component state and force component to rerender 
      // with new data.
      const last_action=store.getState().global_space.last_action_type;
      const last_problem=store.getState().global_space.last_problem;
      
      if(last_action===GET_GOOD_PLACES){
       const places_can_visit=store.getState().global_space.places_can_visit;
         

	let interval = setInterval( getTargetPlace(), 240000);
 	setTimeout(getTargetPlace(), 500); 
 	 this.setState({
        ...this.state,
	 tinterval:interval
        });
		
      }else if(last_action===GET_TARGET_PLACE){
      
        const all_c=store.getState().global_space.countries["ref_country_codes" ];
        const places_can_visit=store.getState().global_space.places_can_visit;
       let place_close=(places_can_visit.length>0)?{lat:all_c[places_can_visit[0].id].latitude,
       			lng:all_c[places_can_visit[0].id].longitude,
       			name:all_c[places_can_visit[0].id].country
       			}:null;
	
	const target=store.getState().global_space.nav_path;
	    Object.keys(places_can_visit).map(function(keyName, keyIndex) {
		  // use keyName to get current key's name
		  // and a[keyName] to get its value
		  
		  if(getDistance(target.origin, {
		  lat:all_c[places_can_visit[keyIndex].id].latitude,
		  lng:all_c[places_can_visit[keyIndex].id].longitude}  )<getDistance( target.origin, place_close)){
			 	 place_close={ 
			 	 lat:all_c[places_can_visit[keyIndex].id].latitude, 
			 	 lng:all_c[places_can_visit[keyIndex].id].longitude,
			 	 name:all_c[places_can_visit[keyIndex].id].country}
		  }
		})
      if(places_can_visit.length>0)
            this.setState({
        ...this.state,
         nav_path:store.getState().global_space.nav_path,
         closest_target: [ store.getState().global_space.nav_path.origin, place_close ],
        });
        setTimeout(getLocationInfo(target.origin.lat,target.origin.lng), 500);  
         
   
      }else if(last_action===GET_FOOT_PRINT){
            this.setState({
        ...this.state,
         footprints:store.getState().global_space.footprints
        });
   
      }else if(last_action===ADD_LOCATION){
            this.setState({
        ...this.state,
          add_response:store.getState().global_space.add_response
        });
      this.setState({
        ...this.state,
	 selected_country:null
        });
   setTimeout( getGoodPlaces(), 500);
      }else if(last_action===GET_SITUATION){
            this.setState({
        ...this.state,
         situation:store.getState().global_space.situation
        });
      this.setState({
        ...this.state,
	 selected_country:null
        });
   setTimeout( getGoodPlaces(), 500);
      }else if(last_action===GET_GEO_CODE){
            this.setState({
        ...this.state,
         my_location:store.getState().global_space.geocode["address"]["CountryCode"]+" in "+ store.getState().global_space.geocode["address"]["Region"]+","+store.getState().global_space.geocode["address"]["City"]
        });
   
      }else if(last_action===GET_COUNTRIES_GEO_CODES){
            
  let country_options = [];
  const all_c=store.getState().global_space.countries["ref_country_codes" ];
  
  Object.keys(all_c).map(function(keyName, keyIndex) {
		  // use keyName to get current key's name
		  // and a[keyName] to get its value
	
		  country_options.push( { value: keyIndex, label:all_c[keyIndex]["country"] })
		  
		})
    	
           
            this.setState({
        ...this.state,
          country_options: country_options
        });
    setTimeout( getGoodPlaces(), 100); 
    
      }else if (last_action === API_ERROR) {
            this.setState({
        ...this.state,
          result: "We have a problem:"+last_problem
        });
      }
    });    
    

   }
	    
	/*setInterval( () => */{
      var seconds=(new Date(this.state.next).getTime()-new Date().getTime()) / 1000;
       if(seconds<0)
       seconds=0;
       var state = Object.assign(this.state, {
		remaining_pc:(seconds/180)*100,
		   	then: this.state.then,
		   	time_left:seconds
		});
		
   	this.setState(state );

      }/*,1000)*/

  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.tinterval); // thanks @Luca D'Amico
     clearInterval(this.state.finterval); // thanks @Luca D'Amico
  }

  render() {  
const all_c=store.getState().global_space.countries["ref_country_codes" ];

  return (

     <TableContainer component={Paper} size={"small"}>
      <Table  aria-label={"simple table"} style={{borderBottom:"none",backgroundColor: 'white', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'  }}>
        <TableHead>
          <TableRow>
            <TableCell style={{borderBottom:"none", width: 400 }}></TableCell>
            <TableCell style={{borderBottom:"none",  width: 300 }}></TableCell>
            <TableCell style={{ borderBottom:"none", width: 400 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{width: '100%'}}>
        <TableRow >
              <TableCell style={{borderBottom:"none", width: 400 }}>

              </TableCell>
              <TableCell style={{borderBottom:"none",  width: 300,textAlign: 'center', height: 60 }}>
            
              </TableCell>
              <TableCell style={{borderBottom:"none", width: 400}}>
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell style={{borderBottom:"none", width: 400}}></TableCell>
              <TableCell style={{borderBottom:"none",textAlign: 'center',  width: 600,color: '#00FFFF',fontWeight:'bold'  }}>  </TableCell>
              <TableCell style={{borderBottom:"none", width: 400}}></TableCell>
            </TableRow>
            <TableRow >
              <TableCell style={{borderBottom:"none"}}>  
              <h1>Situation</h1>
              {this.state.situation}
             <p></p>
              <h2>FootPrint historical days</h2>
              <p></p>
              <Select style={{ backgroundColor: 'black',color:'black'  }} maxMenuHeight={100} options={footprint_history} onChange={this.FootPrintHistoryChange} defaultValue={footprint_history[this.state.max_footprint_days]} />
               </TableCell>
              <TableCell style={{borderBottom:"none"}}>
		 <LoadScript
        		googleMapsApiKey={GOOGLE_MAPS_APIKEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={undefined}
          maxZoom={100}
          zoom={100} >
              <DirectionsServiceMemo
		    destination={this.state.nav_path!=null?this.state.nav_path.destination.name:""}
		    origin={this.state.nav_path!=null?this.state.nav_path.origin:""}
		    travelMode={'WALKING'}
		    callback={this.directionsCallback}
		  
		/>
           
            {
              this.state.response !== null && (
                <DirectionsRenderer
                  // required
                  options={{ 
                    directions: this.state.response
                  }}
                  // optional
                  onLoad={directionsRenderer => {
                    console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                  }}
                  // optional
                  onUnmount={directionsRenderer => {
                    console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                  }}
                />
              )
            }
           {this.state.places_can_visit.map((place) =>  
            <Marker key={place.id} label={all_c[place.id].country} position={{  lat:all_c[place.id].latitude, lng:all_c[place.id].longitude}} > </Marker>
           )} 
          {(this.state.selected_country!=null)? <Marker label={"Choosen "+all_c[this.state.selected_country.value].country} position={{  lat:all_c[this.state.selected_country.value].latitude, lng:all_c[this.state.selected_country.value].longitude}} > </Marker>:null}  
          {this.state.places_can_visit.map((place) =>  
          
            <Polyline 
	      onLoad={onLoadp}
	      path={[this.state.nav_path!=null?this.state.nav_path.origin:null,{  lat:all_c[place.id].latitude, lng:all_c[place.id].longitude, name:all_c[place.id].country}]}
	      
	      options={opt}
   	 />
           )
         }
         
         
        {this.state.footprints.map((line) =>  
         <Polyline
	      onLoad={onLoadp}
	      path={line}
	      options={options_f}
   	 />)
         }
   	  <Polyline
	      onLoad={onLoadp}
	      path={this.state.closest_target}
	      options={options}
   	 />
   	   	 
        </GoogleMap>
      </LoadScript>
              </TableCell>
              <TableCell style={{borderBottom:"none",backgroundColor: 'green',color:'black'}}>
              <h1>Destinations & Reccomendations</h1>
              
              Closest Accomodating Country:<b>{this.state.closest_target.length>1?this.state.closest_target[1].name:""}</b>
              <p></p>
              Currently Location:<b>{this.state.my_location!=null?this.state.my_location:""}</b>
               <p></p>
              Immediate destination:<b>{this.state.nav_path!=null?this.state.nav_path.destination.name:""}</b>
              <p></p>
              Choose a destination you would reccomend
              <p></p>
              <Select style={{ backgroundColor: 'black',color:'black'  }} maxMenuHeight={100} options={this.state.country_options} onChange={this.onSelectChange} defaultValue={this.state.country_options.length>0?this.state.country_options[1]:null} />
               
               <p></p>
               Your preffered destination:<b>{(all_c!=null)&&(this.state.selected_country!=null)?all_c[this.state.selected_country.value].country:""} </b>
                <p></p>We have placed a marker there, press send so it can be added!
                <p></p>
                Tick here Include More Information Or include your contact Info 
                <input
		    name="add_info"            
		    type="checkbox"
		    checked={this.state.some_more_info}
		    onChange={this.handlecheckboxChange} />
		<p></p>    
		 { this.state.some_more_info ? 
		 <div>
		 <p>Add your contact info or More info about this place => </p>
		 <textarea
		    name="email"            
		    style={{ height: "200px", width: "100%",backgroundColor: 'black',color:'white'  }}
		    value={this.state.more_info}
		    onChange={this.handleInfoChange} />
		     <p></p>
		   <b>NB:The info typed above won't be added onto the map.</b> 
		    </div> : null }
		   
		   <p></p>	   
	 <GoogleReCaptchaProvider
		    reCaptchaKey="6Lcwd78aAAAAAGW3gYqVBs8P7FZ6rgORsoYgh937"
		   // language="[optional_language]"
		   // useRecaptchaNet="[optional_boolean_value]"
		    //useEnterprise="[optional_boolean_value]"
		    scriptProps={{
		      async: false, // optional, default to false,
		      defer: false, // optional, default to false
		      appendTo: "body" // optional, default to "head", can be "head" or "body",
		     // nonce: undefined // optional, default undefined
		    }}
		  >	   
              {this.state.selected_country!=null?
              <button onClick={this.handleBClick.bind(this)} > Reccomend</button>
              :<b>Choose a location first</b>}
    </GoogleReCaptchaProvider>
 
		<p></p>
		{ this.state.add_response!=null?"STATUS:"+this.state.add_response.result:null}  
		     
              </TableCell>
            </TableRow>
              
        </TableBody>
      </Table>
    </TableContainer>
    );

  }
}
const mapStateToProps = state => {
    // This is the longhand of what you did
  return {
    global_space:state.global_space,
    };
};
const mapDispatchToProps = {
 getFootPrint,
 getTargetPlace,
 getGoodPlaces,
 getLocationInfo,
 addLocation,
 getSituation
};


export default connect(mapStateToProps, mapDispatchToProps)(App);
