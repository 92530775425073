import {
GET_GOOD_PLACES,
GET_TARGET_PLACE,
GET_FOOT_PRINT,
GET_GEO_CODE,
GET_COUNTRIES_GEO_CODES,
ADD_LOCATION,
GET_SITUATION,
API_ERROR
} from '../types'

const initialState = {
      footprints: [ ],
    places_can_visit:[],
    geocode:[],
    countries:[],
    nav_path:null,
    add_response:""
    

}
function check_if_has_key(array, key) {
    var found = false;
    for (var i in array) {
        // Check max id
        if (i === key) {
            found = true;
            break;
        }
    }
    return found;
}
function xhr_http_reducer(state = initialState, action) {
    state.last_action_type = action.type;

    if (check_if_has_key(action.payload, 'error')) {
        if (check_if_has_key(action.payload.error, 'warning')) {
            return {
                ...state,
                last_problem: action.payload.error.warning,
                last_action_type: API_ERROR,
                loading: false
            }
        }
        else if (check_if_has_key(action.payload.error, 'store')) {
            return {
                ...state,
                last_problem: action.payload.error.store,
                last_action_type: API_ERROR,
                loading: false
            }
        } else {
            return {
                ...state,
                last_problem: "Unkown Problem occured.",
                last_action_type: API_ERROR,
                loading: false
            }
        }
    }


    switch (action.type) {
        case GET_GOOD_PLACES:
            console.log("Habitable land:" + action.payload);
            return {
                ...state,
                places_can_visit: action.payload,
                loading: false

            }
        case GET_TARGET_PLACE:
            console.log("Current navigation desstination received!");
            return {
                ...state,
                nav_path: action.payload,
                loading: false
            }
        case GET_FOOT_PRINT:
            console.log("Footprints updated!");
            return {
                ...state,
                footprints: action.payload,
                loading: false
            }
        case GET_GEO_CODE:
            console.log("Geocode received updated!");
            return {
                ...state,
                geocode: action.payload,
                loading: false
            }
         case GET_COUNTRIES_GEO_CODES:
            console.log("Countries updated!");
            return {
                ...state,
                countries: action.payload,
                loading: false
            }
         case GET_SITUATION:
            console.log("Situation updated!");
            return {
                ...state,
                situation: action.payload,
                loading: false
           }
         case ADD_LOCATION:
            console.log("Location added!");
            return {
                ...state,
                add_response: action.payload,
                loading: false
            }    
        default: return state
    }

}
export default xhr_http_reducer;

