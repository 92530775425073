import {GET_TARGET_PLACE, API_ERROR} from '../types'
import axios from 'axios'
import store from '../store'

window.store = store;

export const getTargetPlace= () => async dispatch => {
    
 try{

  
        const res = await axios.post('https://map.deaddevice.com/nav/target.php')
        store.dispatch( {
            type: GET_TARGET_PLACE,
            payload: res.data.target
        })
    }
    catch(e){
       store.dispatch( {
            type: API_ERROR,
            payload: console.log(e),
        })
    }

}
