import {GET_GOOD_PLACES, API_ERROR} from '../types'
import axios from 'axios'
import store from '../store'

window.store = store;

export const getGoodPlaces= () => async dispatch => {
    
 try{
  
        const res = await axios.post('https://map.deaddevice.com/nav/get_good_places.php')
        store.dispatch( {
            type: GET_GOOD_PLACES,
            payload: res.data.places_can_visit
        })
    }
    catch(e){
        store.dispatch( {
            type: API_ERROR,
            payload: console.log(e),
        })
    }

}
