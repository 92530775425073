import {GET_GEO_CODE, API_ERROR} from '../types'
import axios from 'axios'
import store from '../store'

window.store = store;

export const getLocationInfo= (lat,lng) => async dispatch => {

 try{

        const res = await axios.post('https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=&location='+lng+','+lat)
       store.dispatch( {
            type: GET_GEO_CODE,
            payload: res.data
        })
    }
    catch(e){
        store.dispatch( {
            type: API_ERROR,
            payload: console.log(e),
        })
    }

}
