import {GET_FOOT_PRINT, API_ERROR} from '../types'
import axios from 'axios'
import store from '../store'

window.store = store;

export const getFootPrint= (limit) => async dispatch => {

 try{

        const res = await axios.get('https://map.deaddevice.com/nav/footprint.php?limit='+limit)
       store.dispatch( {
            type: GET_FOOT_PRINT,
            payload: res.data.footprints
        })
    }
    catch(e){
        store.dispatch( {
            type: API_ERROR,
            payload: console.log(e),
        })
    }

}
