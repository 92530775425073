import {GET_COUNTRIES_GEO_CODES, API_ERROR} from '../types'
import axios from 'axios'
import store from '../store'

window.store = store;

export const getCountries= () => async dispatch => {

 try{

        const res = await axios.get('https://raw.githubusercontent.com/eesur/country-codes-lat-long/f814118bc02d7357afa017a12192e7d1fa9aa0b7/country-codes-lat-long-alpha3.json')
       store.dispatch( {
            type: GET_COUNTRIES_GEO_CODES,
            payload: res.data
        })
    }
    catch(e){
        store.dispatch( {
            type: API_ERROR,
            payload: console.log(e),
        })
    }

}
