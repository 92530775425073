import {GET_SITUATION, API_ERROR} from '../types'
import axios from 'axios'
import store from '../store'

window.store = store;

export const getSituation= () => async dispatch => {
    
 try{

  
        const res = await axios.post('https://map.deaddevice.com/nav/situation.php')
        store.dispatch( {
            type: GET_SITUATION,
            payload: res.data.situation
        })
    }
    catch(e){
       store.dispatch( {
            type: API_ERROR,
            payload: console.log(e),
        })
    }

}
