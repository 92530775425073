import {ADD_LOCATION, API_ERROR} from '../types'
import axios from 'axios'
import store from '../store'

window.store = store;

export const addLocation= (recommendation_data) => async dispatch => {
    
 try{
        const res = await axios.post('https://map.deaddevice.com/nav/add_location.php',recommendation_data)
        store.dispatch( {
            type: ADD_LOCATION,
           payload: res.data
        })
    }
    catch(e){
       store.dispatch( {
            type: API_ERROR,
            payload: console.log(e),
        })
    }

}
